import { FastGalleryService } from '../../services/fastGalleryService';
import { getViewMode } from '../../utils/utils';
import model from './model';

export default model.createController(({ $bind, $widget, flowAPI }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      // @ts-expect-error
      const { galleryId } = flowAPI.controllerConfig.config;
      const fastGalleryService = new FastGalleryService({
        viewMode: getViewMode(flowAPI),
        galleryInstanceId: galleryId,
        httpClient: flowAPI.httpClient,
      });
      Promise.all([
        fastGalleryService.getGalleryItemsInCurrentContext(),
        fastGalleryService.getGalleryDataInCurrentContext(),
      ])
        .then(([itemsResponse, galleryDataResponse]) => {
          console.log({
            itemsResponse,
            totalItemsCount: galleryDataResponse?.totalItems,
          });
        })
        .catch((err) => {
          console.error('could not fetch gallery data ', err);
        });
    },
    exports: {},
  };
});
